
import { getCreatoUser} from "./localStorage";

const isBrowser = typeof window !== `undefined`

export const isLoggedIn = () => {
        if (!isBrowser) return false

        const user = getCreatoUser();

        return !!user?.uid;
}