import firebase from 'firebase'
import 'firebase/auth'

const isBrowser = typeof window !== 'undefined'
const firebaseConfig = {
    apiKey: process.env.GATSBY_FIREBASE_APIKEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_FIREBASE_APPID,
    measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
}

if (isBrowser) firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()
const db = firebase.firestore()
const functions = firebase.functions()

var googleProvider = new firebase.auth.GoogleAuthProvider()
var appleProvider = new firebase.auth.OAuthProvider('apple.com')
var facebookProvider = new firebase.auth.FacebookAuthProvider()

auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)

if (process.env.NODE_ENV === 'development') {
    auth.useEmulator('http://localhost:9099')
    db.useEmulator('localhost', 4003)
    functions.useEmulator('localhost', 5001)
}

export { auth, functions, db, googleProvider, appleProvider, facebookProvider }
export default firebase
