import { useCallback, useMemo } from "react";

import firebase from "firebase";
import { setCreatoUser } from "utils/localStorage";

import {
  appleProvider,
  auth,
  facebookProvider,
  googleProvider,
} from "../../utils/firebase/configs";
import useLoading from "../common/useLoading";

export enum IAuth {
  GOOGLE = "google",
  APPLE = "apple",
  FACEBOOK = "facebook",
}

interface LoginHookInterface {
  onCompleted?: () => void;
}

export const useLogin = (
  props: LoginHookInterface = { onCompleted: () => {} }
) => {
  const { onCompleted = () => {} } = props;
  const { isOpen: loading, setLoading } = useLoading();

  const login = useCallback(async (user: firebase.auth.UserCredential['user']) => {
    const { uid } = user!;

    try {
      // const response = await getUser(uid);
      // const { role, username, balance, displayName, photoURL } = response!.data.data;

      setCreatoUser({ uid });
      // dispatchLogin({
      //   id: uid,
      //   username,
      //   role,
      //   balance,
      //   displayName,
      //   photoURL,
      // });
    } catch(error) {
      console.error(error);

      if (uid!.length > 0 ){ 
        
        // const response = await getUser(uid);
        // const { role, username, balance, displayName, photoURL } = response!.data.data;
  
        // setCreatoUser({ uid });
        // dispatchLogin({
        //   id: uid,
        //   username,
        //   role,
        //   balance,
        //   displayName,
        //   photoURL,
        // });
      }
    }
  }, [])

  const loginByEmail = useCallback(
    async (email: string, password: string) => {
      try {
        setLoading(true);

        const { user } = await auth.signInWithEmailAndPassword(email, password);

        await login(user);
      } catch (error) {
        console.log(error);
      } finally {
        // setLoading(false);
        onCompleted();
      }
    },
    [setLoading, login, onCompleted]
  );

  const loginByGoogle = useCallback(async () => {
    try {
      setLoading(true);

      const { user } = await auth.signInWithPopup(googleProvider);

      await login(user);
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false);
      onCompleted();
    }
  }, [setLoading, login, onCompleted]);

  const loginByApple = useCallback(async () => {
    try {
      setLoading(true);

      const { user } = await auth.signInWithPopup(appleProvider);

      await login(user);
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false);
      onCompleted();
    }
  }, [setLoading, login, onCompleted]);

  const loginByFacebook = useCallback(async () => {
    try {
      setLoading(true);

      const { user } = await auth.signInWithPopup(facebookProvider);

      await login(user);
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false);
      onCompleted();
    }
  }, [setLoading, login, onCompleted]);

  return useMemo(
    () => ({
      loading,
      login,
      loginByEmail,
      loginByGoogle,
      loginByApple,
      loginByFacebook,
    }),
    [loading, login, loginByEmail, loginByGoogle, loginByApple, loginByFacebook]
  );
};
