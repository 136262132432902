export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const iOS = () => 
   [
  'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)

  export const isBrowser = typeof window !== "undefined"