import React from "react";

interface ICreatoIconProps extends React.ComponentProps<'svg'> {
}

export const CreatoIcon: React.FunctionComponent<ICreatoIconProps> = ({
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      viewBox="0 0 5000.000000 5000.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,5000.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M27080 39705 c0 -6708 3 -8396 13 -8399 156 -47 525 -174 662 -229 1248 -504 2305 -1362 3042 -2472 335 -503 609 -1073 792 -1645 22 -69 43 -137 47 -152 l6 -28 8431 0 8430 0 -7 93 c-13 176 -64 648 -101 937 -333 2607 -1108 5155 -2282 7505 -962 1924 -2199 3722 -3652 5305 -406 443 -993 1032 -1366 1370 -38 35 -92 84 -120 110 -279 259 -863 753 -1235 1046 -250 197 -710 542 -867 652 -10 7 -101 71 -203 143 -169 119 -636 433 -805 541 -307 195 -519 325 -855 521 -793 462 -1611 878 -2425 1232 -705 306 -1241 512 -1965 754 -129 43 -251 83 -270 89 -19 5 -87 27 -150 47 -112 36 -177 55 -425 128 -588 171 -1134 307 -1760 438 -311 65 -543 108 -855 159 -363 60 -488 79 -675 105 -104 14 -221 30 -260 35 -68 10 -139 18 -535 60 -234 24 -524 50 -572 50 l-38 0 0 -8395z"
        />
        <path
          d="M22965 48053 c-16 -2 -93 -10 -170 -19 -1927 -207 -3845 -662 -5680 -1349 -305 -114 -702 -271 -810 -319 -16 -8 -75 -33 -130 -56 -55 -23 -118 -50 -140 -60 -22 -10 -68 -30 -103 -44 -34 -15 -93 -40 -130 -57 -37 -18 -87 -40 -112 -51 -159 -68 -616 -287 -905 -433 -1181 -597 -2234 -1243 -3305 -2028 -1831 -1343 -3502 -3005 -4862 -4837 -1702 -2294 -2963 -4844 -3737 -7560 -317 -1112 -566 -2333 -701 -3430 -11 -91 -24 -199 -30 -240 -49 -379 -111 -1163 -130 -1650 -6 -146 -15 -377 -20 -515 -13 -318 -13 -692 0 -1010 5 -137 14 -369 20 -515 30 -753 123 -1722 235 -2440 8 -52 26 -167 40 -255 14 -88 29 -185 35 -215 5 -30 21 -120 36 -200 56 -316 148 -767 229 -1120 7 -30 22 -95 33 -145 27 -114 155 -621 188 -740 358 -1285 755 -2387 1282 -3550 116 -255 203 -437 383 -797 844 -1688 1893 -3266 3130 -4708 204 -238 253 -293 524 -589 1394 -1522 3075 -2915 4820 -3995 61 -38 137 -86 170 -106 238 -150 695 -413 1118 -644 339 -186 1001 -514 1422 -706 582 -265 1265 -542 1845 -747 299 -106 963 -324 1100 -361 19 -5 139 -40 265 -77 652 -189 1333 -351 2125 -504 533 -103 1387 -224 2013 -286 l67 -7 0 8464 0 8464 -52 19 c-203 71 -523 208 -732 312 -1086 543 -1996 1365 -2639 2385 -889 1408 -1218 3113 -916 4758 220 1205 777 2336 1605 3260 146 164 446 457 608 595 592 506 1246 895 1979 1176 l137 52 0 8444 0 8443 -37 -1 c-21 -1 -51 -4 -68 -6z"
        />
        <path
          d="M31501 22622 c-111 -306 -288 -688 -454 -982 -752 -1330 -1925 -2354 -3337 -2915 -161 -64 -473 -172 -580 -201 l-35 -10 -3 -8428 -2 -8429 92 7 c559 43 1395 149 2048 261 793 137 1775 360 2455 560 44 13 136 40 205 60 382 111 1009 318 1410 466 880 324 1579 626 2410 1041 386 193 481 242 725 375 66 36 145 79 175 96 322 176 898 520 1265 755 1732 1112 3314 2453 4687 3975 2396 2655 4165 5849 5138 9272 375 1319 649 2767 770 4060 6 61 13 127 16 148 l6 37 -8469 0 -8468 0 -54 -148z"
        />
      </g>
    </svg>
  );
};
