import React from "react";

export type IBaseButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

function BaseButton({ className, children, ...props }: IBaseButtonProps) {
  return <button className={`btn ${className}` }{...props}>{children}</button>;
}

export default BaseButton;
