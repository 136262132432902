import React from "react";

import Modal from "react-modal";
import './Loading.css'

import { CreatoIcon } from '../../Icons/CreatoIcon'

export type ILoadingModalProps = Modal.Props 

const LoadingModal: React.FunctionComponent<ILoadingModalProps>= ({ ...props }) => {
  return (
    <Modal 
      id="loadingModal" 
      ariaHideApp={false}
      className="h-1/2 w-1/6 min-w-min m-auto flex flex-col justify-center bg-transparent border-0 relative top-1/2 transform -translate-y-1/2 focus:outline-none focus:ring-0"
      {...props }
      >
      <CreatoIcon className="fill-primary-500" aria-hidden="true"/>
      <h1 className="text-center">Loading...</h1>
    </Modal>
  );
};

export default LoadingModal;