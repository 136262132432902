import React, { useCallback, useEffect } from "react";

import { SYSTEM_CLOSE_LOADING_TYPE, SYSTEM_OPEN_LOADING_TYPE } from "../../actions/global";
import useGlobalContext from "./useGlobalContext";

type ILoadingProps = boolean

const useLoading = (init = false) => {
  const { globalDispatch, globalState } = useGlobalContext();

  const setLoading = useCallback((loading: ILoadingProps) => {
    const type = loading ? SYSTEM_OPEN_LOADING_TYPE : SYSTEM_CLOSE_LOADING_TYPE;

    globalDispatch({ type })
  },[globalDispatch])

  useEffect(()=>{
    if (init) {
      setLoading(true)
    }
  },[])

  return {
    isOpen: globalState.system.loading,
    setLoading
  };
};

export default useLoading;
