module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Creato","short_name":"Creato","description":"Creat together","lang":"en","start_url":"/","background_color":"#ffffff","theme_color":"#748177","display":"browser","icon":"src/images/creato-icon.png","icons":[{"src":"/images/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/images/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9730c866080b1c0fec12a1a930dcfb47"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
