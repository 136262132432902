import React from "react";

import { PageProps } from "gatsby";
import useLoading from "hooks/common/useLoading";

import Footer from "./common/Footer";
import LoadingModal from "./Modals/Loading";
import Navigator from "./Navigator";

export type ILayoutProps = PageProps;

const Layout: React.FunctionComponent<ILayoutProps> = ({
  children,
  location,
}) => {
  const { isOpen } = useLoading();

  return (
    <>
      <header>
        <Navigator location={location} />
      </header>
      <main>{children}</main>
      <Footer />

      {/* Modal */}
      <LoadingModal isOpen={isOpen} />
    </>
  );
};

export default Layout;
