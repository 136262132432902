import React from "react"

import useDOMMounted from "hooks/common/useDomMount"

const ClientNode: React.FunctionComponent = ({ children }) => {
  const hasMounted = useDOMMounted();

  if (!hasMounted) {
    return null;
  }
 
  return (
    <>{children}</>
  );
}

export default ClientNode;