import { isBrowser } from "./browser";

export const USER = 'creatogether';

interface IUserLocalStorage {
        uid: string;
        // displayName: string;
        // username: string | null
        // role: number
}

export const setCreatoUser = (user: IUserLocalStorage) => {
        const value = JSON.stringify(user);

        isBrowser && localStorage.setItem(USER, value);
}

export const getCreatoUser = () => {
        let data = null;

        if(isBrowser) {
        const value = localStorage.getItem(USER) ?? 'null'

        data = JSON.parse(value);
        }
        
        return data;
}

export const removeCreatoUser = () => {
        isBrowser && localStorage.removeItem(USER);
}

export const clearLocalStorage = () => isBrowser && localStorage.clear()
