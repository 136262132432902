// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-users-tsx": () => import("./../../../src/pages/admin/users.tsx" /* webpackChunkName: "component---src-pages-admin-users-tsx" */),
  "component---src-pages-andrew-tsx": () => import("./../../../src/pages/andrew.tsx" /* webpackChunkName: "component---src-pages-andrew-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-auth-email-tsx": () => import("./../../../src/pages/auth/email.tsx" /* webpackChunkName: "component---src-pages-auth-email-tsx" */),
  "component---src-pages-auth-login-tsx": () => import("./../../../src/pages/auth/login.tsx" /* webpackChunkName: "component---src-pages-auth-login-tsx" */),
  "component---src-pages-auth-logout-tsx": () => import("./../../../src/pages/auth/logout.tsx" /* webpackChunkName: "component---src-pages-auth-logout-tsx" */),
  "component---src-pages-auth-register-tsx": () => import("./../../../src/pages/auth/register.tsx" /* webpackChunkName: "component---src-pages-auth-register-tsx" */),
  "component---src-pages-dare-[id]-tsx": () => import("./../../../src/pages/dare/[id].tsx" /* webpackChunkName: "component---src-pages-dare-[id]-tsx" */),
  "component---src-pages-dare-confirmation-tsx": () => import("./../../../src/pages/dare/confirmation.tsx" /* webpackChunkName: "component---src-pages-dare-confirmation-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-market-item-tsx": () => import("./../../../src/pages/market/item.tsx" /* webpackChunkName: "component---src-pages-market-item-tsx" */),
  "component---src-pages-poll-detail-[id]-bet-option-id-tsx": () => import("./../../../src/pages/poll/detail/[id]/bet/[optionId].tsx" /* webpackChunkName: "component---src-pages-poll-detail-[id]-bet-option-id-tsx" */),
  "component---src-pages-poll-detail-[id]-index-tsx": () => import("./../../../src/pages/poll/detail/[id]/index.tsx" /* webpackChunkName: "component---src-pages-poll-detail-[id]-index-tsx" */),
  "component---src-pages-poll-new-index-tsx": () => import("./../../../src/pages/poll/new/index.tsx" /* webpackChunkName: "component---src-pages-poll-new-index-tsx" */),
  "component---src-pages-poll-new-option-[index]-tsx": () => import("./../../../src/pages/poll/new/option/[index].tsx" /* webpackChunkName: "component---src-pages-poll-new-option-[index]-tsx" */),
  "component---src-pages-poll-new-preview-tsx": () => import("./../../../src/pages/poll/new/preview.tsx" /* webpackChunkName: "component---src-pages-poll-new-preview-tsx" */),
  "component---src-pages-poll-new-title-tsx": () => import("./../../../src/pages/poll/new/title.tsx" /* webpackChunkName: "component---src-pages-poll-new-title-tsx" */),
  "component---src-pages-user-profile-index-tsx": () => import("./../../../src/pages/user/profile/index.tsx" /* webpackChunkName: "component---src-pages-user-profile-index-tsx" */),
  "component---src-templates-poll-detail-tsx": () => import("./../../../src/templates/poll/detail.tsx" /* webpackChunkName: "component---src-templates-poll-detail-tsx" */)
}

