import {
  PollContext,
  TierContext,
  UserContext,
} from "types/context";

import { Category, ID, Option } from "../types/api";
// Auth
export const AUTH_LOGIN_TYPE = "AUTH:LOGIN";
export const AUTH_LOGOUT_TYPE = "AUTH:LOGOUT";

// Tier
export const TIER_SET_TIERS_TYPE = "TIER:SET_TIERS";

// User
export const USER_SET_DATA_TYPE = "USER:SET_BALANCE";

// Polls
export const POLL_SET_POLLS_TYPE = " POLL:SET_POLLS";

// poll item
export const POLL_WINNER_TYPE = "POLL:WINNER";

// System
export const SYSTEM_OPEN_LOADING_TYPE = "SYSTEM:OPEN_LOADING";
export const SYSTEM_CLOSE_LOADING_TYPE = "SYSTEM:CLOSE_LOADING";

//DareMe
export const DAREME_SET_FORM_TYPE = "DAREME:SET_FORM_TYPE";

//Auth
type AUTH_LOGIN_ACTION = {
  type: typeof AUTH_LOGIN_TYPE;
  payload: UserContext;
};

type AUTH_LOGOUT_ACTION = { type: typeof AUTH_LOGOUT_TYPE };

//Tier
type TIER_SET_TIERS_ACTION = {
  type: typeof TIER_SET_TIERS_TYPE;
  payload: TierContext;
};

//User
type USER_SET_BALANCE_ACTION = {
  type: typeof USER_SET_DATA_TYPE;
  payload: UserContext;
};

//Poll list
type POLL_SET_POLLS_ACTION = {
  type: typeof POLL_SET_POLLS_TYPE;
  payload: PollContext[];
};

//Poll item
type POLL_WINNER_ACTION = {
  type: typeof POLL_WINNER_TYPE;
  payload: { winner: Option | null; pollId: ID };
};

// System
type SYSTEM_OPEN_LOADING_ACTION = {
  type: typeof SYSTEM_OPEN_LOADING_TYPE;
};
type SYSTEM_CLOSE_LOADING_ACTION = {
  type: typeof SYSTEM_CLOSE_LOADING_TYPE;
};

//DareMe
type DAREME_SET_FORM_ACTION = {
  type: typeof DAREME_SET_FORM_TYPE;
  payload: {
    deadline: number;
    title: string;
    reward: string;
    category: Category | "";
    teaser: string;
    options: string[];
  };
};

export type IActions =
  | AUTH_LOGIN_ACTION
  | AUTH_LOGOUT_ACTION
  | TIER_SET_TIERS_ACTION
  | USER_SET_BALANCE_ACTION
  | POLL_SET_POLLS_ACTION
  | POLL_WINNER_ACTION
  | SYSTEM_OPEN_LOADING_ACTION
  | SYSTEM_CLOSE_LOADING_ACTION
  | DAREME_SET_FORM_ACTION;
