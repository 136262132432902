import React, { useEffect, useState } from "react";

const useDOMMounted = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted;
};

export default useDOMMounted;
