import React, { useCallback, useEffect, useMemo } from "react";

import { TIER_SET_TIERS_TYPE } from "actions/global";
import { getTiers } from "api/tier";
import { TierContext } from "types/context";

import useGlobalContext from "./useGlobalContext";
import useLoading from "./useLoading";

const getTiersSetting = getTiers;

const useInit = () => {
  const { globalState, globalDispatch } = useGlobalContext();
  const { isOpen, setLoading} = useLoading();

  const getTiers = useCallback(async () => {
    try {
      setLoading(true)
      const response = await getTiersSetting();

      globalDispatch({
        type: TIER_SET_TIERS_TYPE,
        payload: response!.data as TierContext,
      })
  
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getTiers();
  }, []);
  

  return useMemo(() => ({
    loading: isOpen, 
    tiers: globalState.tier
  }), [
    isOpen, 
    globalState.tier
  ]);
};

export default useInit;
