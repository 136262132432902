import React from 'react'

import 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';

import Layout from './src/components/layout';
import Provider from './src/providers'
import PollFormProvider from './src/providers/PollForm'
import './src/styles/global.css'

export const wrapRootElement = ({ element }) => {
  return (
    <Provider>
      {element}
    </Provider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it

  if (props.path.includes('/poll/new')) {
    return (
      <Layout {...props}>
        <PollFormProvider>
          {element}
        </PollFormProvider>
      </Layout> 
    )
  }
  
  return (  
    <Layout {...props}>
      {element}
    </Layout>  
  )
}