import React from "react";

import { CreatoIcon } from "components/Icons/CreatoIcon";


interface IBalanceProps {
  loading: boolean;
  value: number;
  iconColor?: React.SVGProps<SVGPathElement>["className"];
  textColor?: React.HtmlHTMLAttributes<HTMLHeadingElement>["className"];
}

const Balance: React.FunctionComponent<IBalanceProps> = ({
  loading,
  value,
  textColor = "",
}) => {
  return (
    <div className="flex items-center justify-between">
      <CreatoIcon className={`w-4 h-4 fill-neutral-500`}/>
      <h5 className={`text-primary-500 ml-2 text-sm md:text-base ${textColor}`}>
        {loading ? "loading " : Number(value)?.toLocaleString()}
      </h5>
    </div>
  );
};

export default Balance;
