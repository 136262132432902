import React, { Fragment } from "react";

import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { PlusSmIcon } from '@heroicons/react/solid'
import ClientNode from "components/ClientNode";
import LoginButton from "components/common/Buttons/Login";
import { Link, PageProps } from "gatsby";
import { classNames } from "helper/style";
import useGlobalContext from "hooks/common/useGlobalContext";
import useUserListener from "hooks/user/useUserListener";
import { IGlobalStateKey } from "types/context";
import { isLoggedIn } from "utils/auth";

import { Role } from "../../types/api";
import Balance from "../Balance";
import { CreatoIcon } from "../Icons/CreatoIcon";
import { ILinkProps } from "./types";

export type INavigatorProps = Partial<PageProps>;

const navigation: ILinkProps[] = [
  {
    roles: [Role.USER, Role.PLAYER, Role.CREATOR],
    path: {
      to: "/",
    },
    name: "DareMe",
  },
  {
    roles: [Role.USER, Role.PLAYER, Role.CREATOR],
    path: {
      to: "/poll/new",
    },
    name: "Create",
  },
  {
    roles: [Role.USER, Role.PLAYER, Role.CREATOR],
    path: {
      replace: true,
      to: "https://www.creatogether.app/audience",
    },
    name: "How It works",
  },
  {
    roles: [Role.ADMIN],
    path: {
      to: "/admin/users",
    },
    name: "Admin",
  },
];

const menu: ILinkProps[] = [
  {
    roles: [Role.PLAYER, Role.CREATOR],
    path: {
      to: "/user/profile/"
    },
    name: "Profile",
  },
  {
    roles: [Role.USER],
    path: {
      to: "/auth/login/"
    },
    name: "Sign In",
  },
  {
    roles: [Role.PLAYER, Role.CREATOR, Role.ADMIN],
    path: {
      to: "/auth/logout/"
    },
    name: "Sign out",
  }
]

const Navigator: React.FunctionComponent<INavigatorProps> = ({ location }) => {
  const { globalState } = useGlobalContext();
  const role = globalState[IGlobalStateKey.USER].role as Role;
  const { loading, user } = useUserListener();

  return (
    <Disclosure as="nav" className="bg-white shadow dark:bg-black">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/" className="w-9 h-9 md:w-12 md:h-12">
                    <CreatoIcon className="fill-primary-500" aria-hidden="true"/>
                  </Link>
                  <span className="ml-2 flex-auto font-bold text-base text-primary-500 lg:text-2xl md:text-xl">
                    <ClientNode>
                      {isLoggedIn() ? `Welcome, ${user.displayName ?? "you"}!` : "Creato"}
                    </ClientNode>
                  </span>
                </div>
                <div className="hidden md:ml-6 md:flex md:space-x-8">
                  {/* Current: "border-primary-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  {navigation.map(({ roles, path, name, ...page }, i) => (
                    role in Role && roles.includes(role) && 
                    <Link 
                      key={`NavLink_${i}`}
                      className="border-transparent text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                      activeClassName="underline"
                      to={path.to}
                    >
                      {name}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex items-center">
              { isLoggedIn() ?
                <>
                  <div className="flex-shrink-0">
                    {/* <button
                      type="button"
                      className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                      <PlusSmIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                      <span>New Job</span>
                    </button> */}
                      <Balance
                        loading={loading}
                        value={user.balance ?? 0}
                      />
                  </div>
                  <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                    {/* <button
                      type="button"
                      className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}

                    {/* Profile menu */}
                    <Menu as="div" className="ml-3 relative z-10">
                      <div>
                        <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                          <span className="sr-only">Open user menu</span>
                          <ClientNode>
                            {user.photoURL && 
                              <img
                                className="h-8 w-8 rounded-full"
                                src={user.photoURL}
                                alt={user.displayName || "Creato Avatar"}
                              />
                            }
                          </ClientNode>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {menu.map(({ roles, path, name }, i) =>
                            role in Role && roles.includes(role) && <Menu.Item>
                              {({ active }) => (
                                <Link
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                  to={path.to}
                                >
                                  {name}
                                </Link> 
                              )}
                            </Menu.Item>
                          )}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </> : 
                <LoginButton />
              }
              </div> 
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {/* Current: "bg-primary-50 border-primary-500 text-primary-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              {navigation.map(({ roles, path, name, ...page }, i) => (
                role in Role && roles.includes(role) && <Link 
                  key={`NavLink_${i}`}
                  className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                  activeClassName="bg-primary-50 border-primary-500 text-primary-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                  to={path.to} 
                >
                  <Disclosure.Button
                    as="a"
                    href="#"
                    // className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
                  >
                    {name}
                  </Disclosure.Button>
                </Link>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              {isLoggedIn() && 
                <div className="flex items-center px-4 sm:px-6">
                  <div className="flex-shrink-0">
                    <ClientNode>
                      {user.photoURL && 
                        <img
                          className="h-10 w-10 rounded-full"
                          src={user.photoURL}
                          alt={user.displayName || 'Creato Avatar'}
                        />
                      }
                    </ClientNode>
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">{user.displayName}</div>
                    <div className="text-sm font-medium text-gray-500">{user.email}</div>
                  </div>
                  {/* <button
                    type="button"
                    className="ml-auto flex-shrink-0 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}
                </div>
              }
              <div className="mt-3 space-y-1">
                {menu.map(({ roles, path, name }, i) =>
                  role in Role && roles.includes(role) && 
                  <Link
                   key={`menu_Link_${i}`}
                    to={path.to}
                  >
                    <Disclosure.Button
                      as="a"
                      // href="#"
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
                    >
                      {name}
                    </Disclosure.Button>
                  </Link> 
                )}
              </div>
              </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
};

export default Navigator;
