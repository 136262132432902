import React from "react";

import { Link } from "gatsby";

import PrimaryButton from "./Primary";

function LoginButton() {
  return (
    <Link to="/auth/login">
      <PrimaryButton disabled={false} className="px-3 py-2">
        Sign In
      </PrimaryButton>
    </Link>
  );
}

export default LoginButton;
