import React, { ReactNode } from "react";

import { classNames } from "helper/style";

import BaseButton, { IBaseButtonProps } from "./BaseButton";

export interface IPrimaryButtonProps extends IBaseButtonProps {
  icon?: ReactNode;
  iconClassName?: string;
  secondary?: boolean;
}

const PrimaryButton: React.FunctionComponent<IPrimaryButtonProps> = ({
  children,
  // onClick,
  // disabled,
  className,
  secondary,
  icon,
  iconClassName,
  ...props
}) => {
  return (
    <BaseButton 
      className={classNames(
        "btn btn-fill btn-primary",
        className || "",
      )} 
      {...props}
    >
      {children}
    </BaseButton>
  )
};

export default PrimaryButton;
