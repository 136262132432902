import { useCallback, useEffect, useMemo} from "react";

import useLoading from "hooks/common/useLoading";
import useUserListener from "hooks/user/useUserListener";

import { useLogin } from "./useLogin";
import { useLogout } from "./useLogout";

const useAuthListener = () => {
  const { isOpen: loading, setLoading } = useLoading();
  const { onLogout } = useLogout();
  const { login } = useLogin();
  
  useUserListener({
    onCompleted: async (data) => {
      if (!data) {
        onLogout();
        return;
      }

      await login(data); 
    }
  });

  const listener = useCallback(async (user) => {
    try {
      setLoading(true);
      if (!user) {
        onLogout();
        return;
      }

      setTimeout(async function() { await login(user); }, 3000);
    } catch(error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [setLoading, onLogout, login])

  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged(listener);

  //   return () => unsubscribe();
  // }, []);
  
return useMemo(() => ({ loading }), [loading])
};

export default useAuthListener;
