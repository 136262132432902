import React, { useReducer } from "react";

import useAuthListener from "hooks/auth/useAuthListener";
import useInit from "hooks/common/useInit";

import { GlobalContext } from "../contexts/Global";
import { Reducer } from "../reducers";
import { initialState } from "../types/context";

const Provider: React.FunctionComponent = ({ children }) => {
  const [globalState, globalDispatch] = useReducer(Reducer, initialState);
  
  useInit();
  useAuthListener();

  return (
    <GlobalContext.Provider value={{ globalState, globalDispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default Provider;
