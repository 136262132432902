import React from "react";

const HOST = "https://www.creatogether.app/"

const buttons = [
  { key: 'about_us', value: 'About Us', route: ''}, 
  { key: 'help', value: 'Help', route: 'apply'}, 
  { key: 'terms_condition', value: 'Terms & Condition', route: 'terms'}, 
  { key: 'privacy', value: 'Privacy', route: 'privacypolicy'} 
]

const Footer: React.FunctionComponent = () => {
  return (
    <footer className="w-full h-auto flex flex-col">
      <ul className="bg-primary-500 flex justify-center gap-x-4">
        {
          buttons.map((button) => (
            <li className="text-white text-sm" key={button.key}><a href={`${HOST}${button.route}`}>{button.value}</a></li>
          ))
        }
      </ul>
      <span className="bg-primary-500 text-sm text-white text-center">&#169;{`${new Date().getFullYear()}, Creato All Rights Reserved.`}
      </span>
    </footer>
  );
};

export default Footer;
