import TIER_NAME_MAP from "constants/tiers";

import { IPollFormActions, POLLFORM_SET_FORM_VALUE_TYPE } from "actions/pollform";
import produce from "immer";
import { Role } from "types/api";

import {
  AUTH_LOGIN_TYPE,
  AUTH_LOGOUT_TYPE,
  IActions,
  POLL_SET_POLLS_TYPE,
  POLL_WINNER_TYPE,
  SYSTEM_CLOSE_LOADING_TYPE,
  SYSTEM_OPEN_LOADING_TYPE,
  TIER_SET_TIERS_TYPE,
  USER_SET_DATA_TYPE,
} from "../actions/global";
import { IGlobalStateKey, initialPollFormState, initialState } from "../types/context";

const { USER, POLL, SYSTEM, TIER } = IGlobalStateKey;

export const Reducer = (state = initialState, action: IActions) => {
  switch (action.type) {
    //Auth
    case AUTH_LOGIN_TYPE:
      return produce(state, (draft) => {
        draft[USER].id = action.payload.id;
        draft[USER].role = action.payload.role;
        draft[USER].username = action.payload.username;
        draft[USER].balance = action.payload.balance;
        draft[USER].photoURL = action.payload.photoURL;
      });

    case AUTH_LOGOUT_TYPE:
      return produce(state, (draft) => {
        draft[USER].id = "";
        draft[USER].role = Role.USER;
        draft[USER].balance = 0;
        draft[USER].username = "Visitor";
        draft[USER].photoURL = "";
      });

    // Tier
    case TIER_SET_TIERS_TYPE:
      return produce(state, (draft) => {
        action.payload.forEach((tier) => {
          draft[TIER].push({ ...tier, displayName: TIER_NAME_MAP[tier.key] });
        });
      });

    //User
    case USER_SET_DATA_TYPE:
      return produce(state, (draft) => {
        draft[USER].id = action.payload.id;
        draft[USER].role = action.payload.role;
        draft[USER].username = action.payload.username;
        draft[USER].balance = action.payload.balance;
        draft[USER].displayName = action.payload.displayName;
        draft[USER].photoURL = action.payload.photoURL;
      });

    //Poll List
    case POLL_SET_POLLS_TYPE:
      return produce(state, (draft) => {
        draft[POLL] = action.payload;
      });

    case POLL_WINNER_TYPE: {
      const { winner, pollId } = action.payload;
      return produce(state, (draft) => {
        const index = draft[POLL].findIndex((poll) => poll.id === pollId);
        if (index !== -1) {
          draft.polls.map((poll) => {
            if (poll.id === pollId) poll.winner = winner;
          });
        }
      });
    }

    // System
    case SYSTEM_CLOSE_LOADING_TYPE: {
      return produce(state, (draft) => {
        draft[SYSTEM].loading = false;
      });
    }
    case SYSTEM_OPEN_LOADING_TYPE: {
      return produce(state, (draft) => {
        draft[SYSTEM].loading = true;
      });
    }

    default:
      return state;
  }
};


export const PollFormReducer = (state = initialPollFormState, { type, payload }: IPollFormActions) => {
  switch (type) {
    case POLLFORM_SET_FORM_VALUE_TYPE: {
      return produce(state, (draft) => {
        draft.deadline = payload.deadline!;
        draft.title = payload.title!;
        // draft.reward = payload.reward!;
        draft.category = payload.category!;
        draft.teaser = payload.teaser!;
        draft.image = payload.image!;
        draft.options = payload.options!;
      });
    }
    default:
      return state;
  }
};
