import React, { useReducer } from "react";

import { PollFormContext } from "contexts/PollForm";

import { PollFormReducer } from "../reducers";
import { initialPollFormState } from "../types/context";

const PollFormProvider: React.FunctionComponent = ({ children }) => {
  const [pollForm, pollFormDispatch] = useReducer(PollFormReducer, initialPollFormState);


  return (
    <PollFormContext.Provider value={{ pollForm, pollFormDispatch }}>
      {children}
    </PollFormContext.Provider>
  );
};

export default PollFormProvider;
