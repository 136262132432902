import { useCallback, useMemo, useState } from "react";

import useGlobalContext from "hooks/common/useGlobalContext";
import useLoading from "hooks/common/useLoading";

import { AUTH_LOGOUT_TYPE } from "../../actions/global";
import { auth } from "../../utils/firebase/configs";
import { clearLocalStorage } from "../../utils/localStorage";

interface LogoutHookInterface {
  onCompleted?: () => void;
}

export const useLogout = (
  props: LogoutHookInterface = { onCompleted: () => {} }
) => {
  const { onCompleted = () => {} } = props;
  const { globalDispatch } = useGlobalContext();
  const { isOpen: loading, setLoading } = useLoading();

  const onLogout = useCallback(async () => {
    try {
      setLoading(true);
      await auth.signOut();
      globalDispatch({ type: AUTH_LOGOUT_TYPE });
    } catch (error) {
      console.log(error);
    } finally {
      onCompleted();
      clearLocalStorage();
      setLoading(false);
    }
  }, []);

  return useMemo(
    () => ({
      onLogout,
      loading,
    }),
    [onLogout, loading]
  );
};
