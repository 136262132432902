import { ID, TierData } from 'types/api';

import axios from '../utils/axios';

const DEFAULT_ROUTE = '/tiers';

export interface TierResponse extends TierData {
}
 
export const getTiers = async () => {
  try {
    return await axios.get(`${DEFAULT_ROUTE}`);
  } catch(error) {
    console.error(error);
    throw error;
  }  finally {
    
  }
}